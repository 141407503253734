<template>
  <div class="section">
    <back-button label="Dimensiones" to="/dimensions" />

    <div class="box">
      <h2>Metodología para medir el Engagement</h2>
    </div>

    <div class="box">
      <h4>
        ¿Sabía que mejorar el compromiso con tus colaboradores hará tu empresa
        más rentable?
      </h4>
      <p>
        Investigaciones comprueban que el compromiso es de gran valor para el
        trabajo, pues el sentido de pertenencia a la organización y ese deseo de
        mejora para la misma, hará al trabajador más productivo, beneficiando
        así a la empresa.
      </p>
      <p>
        Sin embargo, es importante entender que este compromiso se obtiene
        cuando se crea un ambiente de confianza y se logra conectar de manera
        asertiva.
      </p>
      <p>
        Para determinar el sentido de pertenencia de un trabajador es importante
        tomar en cuenta el ambiente laboral, las metas y valores de la
        organización, y el bienestar propio.
      </p>
      <p>
        Nuestra metodología, a través de 4 preguntas dirigidas, permite
        identificar el grado de compromiso que tienen los colaboradores con la
        organización, lo que permite tomar decisiones de alto impacto en la
        empresa.
      </p>
    </div>

    <div class="flex">
      <div class="col">
        <div class="box">
          <h3>Crecimiento Personal</h3>
          <p>
            ¿Cuentas con los recursos que te permiten aprender y crecer para ser
            cada vez mejor?
          </p>
        </div>
        <div class="box">
          <h3>Desarrollo Profesional</h3>
          <p>
            ¿Los objetivos de tu puesto de trabajo están alineados con tus
            objetivos profesionales?
          </p>
        </div>
        <div class="box">
          <h3>Reconocimiento</h3>
          <p>¿Tu esfuerzo es reconocido por tu jefe directo?</p>
        </div>
        <div class="box">
          <h3>Pertenencia</h3>
          <p>
            ¿En la organización te sientes parte de un equipo de trabajo donde
            quieres pertenecer?
          </p>
        </div>
      </div>
      <div class="col">
        <img src="../../assets/compromiso.jpg" alt="medición del compromiso" />
      </div>
    </div>

    <logo />
  </div>
</template>

<script>
import BackButton from "../../components/BackButton.vue";
import Logo from "../../components/Logo.vue";

export default {
  name: "EngagementModal",

  components: { Logo, BackButton }
};
</script>

<style scoped>
.section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
}

.box {
  margin: var(--lengthSm1);
  padding: var(--lengthMd1);
  background-color: var(--grayColor1);
  border-radius: 10px;
}

.col .box + .box {
  margin-top: var(--lengthLg2);
}

.col:first-child {
  flex-basis: 65%;
}

.col:last-child {
  flex-basis: 35%;
}

h3 {
  margin-bottom: var(--lengthSm1);
  color: var(--mainColor1);
}

h4,
p:not(h3 + p) {
  text-align: center;
}

p {
  color: var(--fontColor1);
}

p + p,
h4 + p {
  margin-top: var(--lengthMd1);
}

.logo-container {
  position: absolute;
  bottom: -100px;
  right: 10px;
}

.back-btn {
  position: absolute;
  top: var(--lengthLg1);
  left: var(--lengthLg1);
}
</style>
